import React from 'react';
import Nav from '../components/Nav/Nav';

export default function aboutPage() {
  return (
    <div>
      <Nav />
      <h1>Statement - Sandor Monos</h1>

      <blockquote>
        I’ve been drawing, carving, painting all my life,
        <br />
        perhaps longer. ……… I just don’t remember.
      </blockquote>
      <p>
        Growing up I had good guidance on how to put ideas into form from
        teachers, friends and company I kept and valued.
        <br />
        What to do seems to come easy – so many things around us, things that
        belong to now, the ones we walk by. The execution is a long struggle to
        satisfy myself.
        <br />
        To show what is there is a great skill, craft. Nothing less than art.
        But the goal is to go beyond that. The hard part is to cross the line,
        when you will show more than the reality, and do it with a simple form
        that you don’t have to explain. <br />
        If you do crossed the line, it doesn’t mean that you’re on the other
        side.
        <br />
        I love shaping the piece to the idea … defend it on completion … hear
        what others see in it. A smile is a great reward !<br />
      </p>

      <h1>Curriculum Vitae</h1>
      <p>
        I was born in Hungary, 1954. <br />
        During my school years I began to draw and paint under the guidance of
        local artist – teachers. <br />
        Always belonged to an Art Club where I was experimenting with oil,
        watercolor and charcoal drawing.
        <br />
        In the fall of 1977 I came to Canada where I continued drawing and
        painting at home beside my full time job. <br />
        Took night courses at Mohawk and Dundas Valley. <br />
        In 1984 I became interested in cartoon drawing. The Hamilton Spectator
        published my work in the Editorials, for a few years.
        <br />
        In 1993 a local sculptor, introduced me to bronze casting. Learned the
        trade from and with him.
        <br />
        I’ve been working with the lost-wax method since that time, and
        experimenting new ways to cast bronze economically to achieve the
        desired surface on the casted piece.
        <br />
        Sandor Monos
      </p>
    </div>
  );
}
